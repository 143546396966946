import React from "react";
import {Divider, IconButton, Modal, TableCell, Text} from "@nike/eds";
import {Link} from "react-router-dom";
import {mapRequestType, mapSource} from "node.glds-react-component-library";
import {RequestStructuredLog} from "../../graphql/api";
import moment from 'moment';
import {mapDocumentType} from "node.glds-react-component-library";

function PrintRequestSearchResultItem(
    props: {
        searchResult: RequestStructuredLog,
        highlightedTraceId: string,
        setHighlightedTraceId: (traceId: string) => void}
) {

    const [summaryModalVisible, setSummaryModalVisible] = React.useState(false);
    const statusClass = props.searchResult.status === "ERROR" ? 'errorStatus' : '';
    const hoverClass = props.searchResult.traceId === props.highlightedTraceId ? 'highlighted' : '';

    return (
        <tr>
            <TableCell className={[statusClass,hoverClass].join(" ")}>{formatDate(props.searchResult.requestTimestamp)}</TableCell>
            <TableCell className={[statusClass,hoverClass].join(" ")}>{props.searchResult.tenant ? mapSource(props.searchResult.tenant) : ''}</TableCell>
            <TableCell className={[statusClass,hoverClass].join(" ")}>{props.searchResult.sourceId}</TableCell>
            <TableCell className={[statusClass,hoverClass].join(" ")}>{mapDocumentType(props.searchResult.documentType as string)}</TableCell>
            <TableCell className={[statusClass,hoverClass].join(" ")}>{mapRequestType(props.searchResult.requestType as string)}</TableCell>
            <TableCell className={[statusClass,hoverClass].join(" ")}>{props.searchResult.printer}</TableCell>
            <TableCell className={[statusClass,hoverClass].join(" ")}>{props.searchResult.errorDescription}</TableCell>
            <TableCell className={[statusClass,hoverClass].join(" ")}>{props.searchResult.duration}</TableCell>
            <TableCell className={[statusClass,hoverClass].join(" ")}>
                <IconButton
                    variant="ghost"
                    as={Link}
                    to={"details/" + props.searchResult.requestId}
                    label=""
                    icon="Show"
                />
                {props.searchResult.requestSummary && props.searchResult.requestSummary.length > 0 &&
                    <>
                        <IconButton
                            onClick={() => setSummaryModalVisible(!summaryModalVisible)}
                            onMouseOver={() => props.setHighlightedTraceId(props.searchResult.traceId ?? '')}
                            onMouseLeave={() => props.setHighlightedTraceId('')}
                            variant="ghost"
                            label=""
                            icon="Link"
                            style={{outline: 'none'}}
                        />
                        <Modal
                            isOpen={summaryModalVisible}
                            onDismiss={() => setSummaryModalVisible(false)}
                            headerSlot={"Group siblings of " + props.searchResult.sourceId}
                        >
                            <Divider/>
                            <div className="eds-grid eds-grid--m-cols-3 group-siblings-grid">
                                {props.searchResult.requestSummary.map(item => {
                                    return (
                                        <>
                                            <IconButton
                                                variant="ghost"
                                                as={Link}
                                                to={"details/" + item?.requestId}
                                                label=""
                                                icon="Show"
                                                className="eds-grid--item-center"
                                            />
                                            <Text className="eds-grid--item-center">{mapDocumentType(item?.documentType as string)}</Text>
                                            <Text className="eds-grid--item-center">{item?.sourceId}</Text>
                                        </>
                                    );
                                })}
                            </div>
                        </Modal>
                    </>
                }
            </TableCell>
        </tr>
    );
}

function formatDate(date: string | null | undefined): string {
    try {
        if (moment(date, moment.ISO_8601, true).isValid()) {
            return moment(date, moment.ISO_8601).format("DD/MM/YYYY HH:mm:ss");
        }
    } catch (e) {
    }
    return '';
}

export {PrintRequestSearchResultItem};