import "@nike/eds/dist/index.css";
import React, {useContext} from "react";
import {Route, Routes} from 'react-router-dom'
import {PrintRequestDetail} from "./components/detail/PrintRequestDetail";
import {PrintRequestSearchOverview} from "./components/search/PrintRequestSearchOverview";
import {AppsyncService} from "./components/shared/AppsyncService";
import {oktaAuth} from './components/config/OktaConfig'
import {StructuredLogsService} from "./components/shared/StructuredLogsService";
import {ApplicationSnackbar, DownloadModal, DownloadContext,  SnackbarContext} from "node.glds-react-component-library";

const appsyncService: AppsyncService = new AppsyncService(oktaAuth)
const structuredLogsService: StructuredLogsService = new StructuredLogsService(appsyncService)

export function App() {
    const snackbarCtx = useContext(SnackbarContext);
    const downloadCtx = useContext(DownloadContext);
    return (
        <main className="mainContentLayout">
            <Routes>
                <Route path="/request-overview" element={<PrintRequestSearchOverview structuredLogsService={structuredLogsService}/>}/>
                <Route path="/request-overview/details/:requestId" element={<PrintRequestDetail structuredLogsService={structuredLogsService}/>}/>
            </Routes>
            {snackbarCtx.isDisplayed && <ApplicationSnackbar/>}
            {downloadCtx.isDisplayed && <DownloadModal/>}
        </main>
    );
}

export default App;
