import {AppsyncService} from './AppsyncService'
import gql from "graphql-tag";
import {ApolloQueryResult} from "apollo-client/core/types";

import {
    getLogMessages,
    getPayload,
    getStructuredLogs,
    listRequests,
    queryOpenSearch,
} from "../../graphql/queries";
import {RequestStructuredLogConnection} from "../../graphql/api";

export class StructuredLogsService {

    constructor(private appsyncService: AppsyncService) {
    }

    async getStructuredLogsRequest(requestId: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getStructuredLogs), fetchPolicy: 'network-only', variables: {requestId: requestId}
            }
        );
    }

    async getPayload(region: string, bucket: string, key: string, version: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getPayload), fetchPolicy: 'network-only', variables: {region: region, bucket: bucket, key: key, version: version}
            }
        );
    }

    async getLogMessages(traceId: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getLogMessages), fetchPolicy: 'network-only', variables: {traceId: traceId}
            }
        );
    }

    async listRequests(nextToken: string | undefined, startDate: Date | undefined, endDate: Date | undefined): Promise<RequestStructuredLogConnection> {
        return this.appsyncService.appSyncClient.query({
                query: gql(listRequests), fetchPolicy: 'network-only', variables: {
                    nextToken: nextToken,
                    epochTimestampFrom: startDate ? startDate.getTime() : null,
                    epochTimestampTo: endDate ? endDate.getTime() : null
                }
            }
        ).then((result: ApolloQueryResult<any>) => {
            return result.data.listRequests;
        });
    }

    async queryOpenSearch(sortCursor: string[] | undefined,
                          globalIdSearch: boolean | undefined,
                          tenant: string | undefined,
                          documentType: string | undefined,
                          requestType: string | undefined,
                          errorCode: string | undefined,
                          printer: string | undefined,
                          sourceId: string | undefined,
                          traceId: string | undefined,
                          startDate: Date | undefined,
                          endDate: Date | undefined): Promise<RequestStructuredLogConnection> {
        return this.appsyncService.appSyncClient.query({
                query: gql(queryOpenSearch), fetchPolicy: 'network-only', variables: {
                    globalIdSearch: globalIdSearch,
                    tenant: tenant,
                    documentType: documentType,
                    requestType: requestType,
                    errorCode: errorCode,
                    printer: printer,
                    sourceId: sourceId,
                    traceId: traceId,
                    epochTimestampFrom: startDate ? startDate.getTime() : undefined,
                    epochTimestampTo: endDate ? endDate.getTime() : undefined,
                    sortCursor: sortCursor
                }
            }
        ).then((result: ApolloQueryResult<any>) => {
            return result.data.queryOpenSearch;
        });
    }
}

export function extractPropertiesMapFromString(properties: string): Map<string, string> {
    if (!properties) {
        return new Map<string, string>();
    }

    // example properties "{jobId=166, cupsInstance=456423356355244, resultCode=0x0000, resultDescription=(successful-ok), resultMessage=}"
    // remove surrounding {} and split into different properties
    let propertiesMap = new Map<string, string>();
    properties.substring(1, properties.length - 1)
        .trim()
        .split(/,(?![^()]*\))/)
        .forEach(function (property) {
            let splitted = property.split(/=(.*)/s);
            if (splitted.length > 1) {
                let key = splitted[0].trim();
                let value = splitted[1].trim();
                propertiesMap.set(key, value);
            }
        });
    return propertiesMap;
}
