export function isNotBlank(value: string | null | undefined): boolean {
    return value !== null && value !== undefined && value.trim() !== '';
}

export function isBlank(value: string | null | undefined): boolean {
    return value === null || value === undefined || value.trim() === '';
}

export function sanitize(value: string | undefined): string | undefined {
    if (value === null || value === undefined) {
        return value;
    }
    const illegalChars = /([+\-=&|><!(){}[\]^"~*?:\\/])/g;
    return value.replace(illegalChars, '\\$1');
}
