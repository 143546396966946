import React, {useState} from "react";
import {Card, Skeleton, Table, TableCell, TableHeading} from "@nike/eds";
import {PrintRequestSearchResultItem} from "./PrintRequestSearchResultItem";
import {RequestStructuredLog} from "../../graphql/api";

function PrintRequestSearchResults(props: {
    searchResults: RequestStructuredLog[],
    loading: boolean
}) {

    const [highlightedTraceId, setHighlightedTraceId] = useState<string>('')

    const headers = [
        {Header: "Request timestamp"},
        {Header: "Source"},
        {Header: "Source ID"},
        {Header: "Document type"},
        {Header: "Request type"},
        {Header: "Printer"},
        {Header: "Error description"},
        {Header: "Duration(ms)"},
        {Header: "Details"}
    ];

    return (
        <Card className="eds-spacing--p-8 eds-flex eds-flex--direction-column">
            <Table className="eds-spacing--p-8" style={{wordBreak: "break-word"}}>
                <thead>
                <tr>
                    {headers.map(column => (<TableHeading>{column.Header}</TableHeading>))}
                </tr>
                </thead>
                <tbody>
                {props.loading &&
                    <>
                        <tr>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        </tr>
                    </>
                }
                {props.searchResults.map(searchResult => (
                    <PrintRequestSearchResultItem searchResult={searchResult} highlightedTraceId={highlightedTraceId} setHighlightedTraceId={setHighlightedTraceId}/>
                ))}
                </tbody>
            </Table>
        </Card>
    );
}

export {PrintRequestSearchResults};
