import React, {useContext, useEffect, useState} from "react";
import {PrintRequestDetailHeader} from "./PrintRequestDetailHeader";
import {PrintRequestDetailOverview} from "./PrintRequestDetailOverview";
import {RequestStructuredLog} from "../../graphql/api";
import {SnackbarContext, SnackbarStatus} from "node.glds-react-component-library";
import {StructuredLogsService} from "../shared/StructuredLogsService";
import {useLocation, useParams} from "react-router-dom";

export function PrintRequestDetail(props) {
    const location = useLocation();

    const params = useParams();

    const snackbarCtx = useContext(SnackbarContext);

    const [request, setRequest] = useState<RequestStructuredLog>()
    const [events, setEvents] = useState<RequestStructuredLog[]>()

    const structuredLogsService: StructuredLogsService = props.structuredLogsService;

    useEffect(() => {
        setRequest(undefined);
        setEvents([]);

        const requestId = params.requestId;

        if (!requestId) {
            snackbarCtx.displayMsg("No requestId given", SnackbarStatus.error);
        } else {
            structuredLogsService.getStructuredLogsRequest(requestId)
                .then(result => {
                    let structuredLogRequest: RequestStructuredLog = result.data.getStructuredLogs.items.find(item => item.type === "Request");
                    if (!structuredLogRequest) {
                        snackbarCtx.displayMsg("No data available for this request. Try refresh to fetch the data again.", SnackbarStatus.error);
                    } else {
                        setRequest(structuredLogRequest)
                        setEvents(result.data.getStructuredLogs.items.filter(item => item.type === "Event"))
                    }
                })
                .catch(error => {
                    snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
                })
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="eds-flex eds-flex--direction-column eds-gap--16">
            <PrintRequestDetailHeader request={request}/>
            <PrintRequestDetailOverview request={request} events={events} structuredLogsService={props.structuredLogsService}/>
        </div>
    );
}

