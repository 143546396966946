import React, {useContext, useEffect, useState} from "react";
import {PrintRequestSearchForm} from "./PrintRequestSearchForm";
import {PrintRequestSearchResults} from "./PrintRequestSearchResults";
import {RequestedSearchForm} from "../../model/RequestedSearchForm";
import {Button} from "@nike/eds";
import {SnackbarContext, SnackbarStatus} from "node.glds-react-component-library";
import {StructuredLogsService} from "../shared/StructuredLogsService";
import {RequestStructuredLog, RequestStructuredLogConnection} from "../../graphql/api";
import {sanitize, isBlank} from "../util/StringUtil";

interface Cursor {
    nextToken: string | undefined;
    sortCursor: string[] | undefined;
}

interface Count {
    value: number | null| undefined;
    relation: string | null | undefined;
}

export function PrintRequestSearchOverview(props) {

    const structuredLogsService: StructuredLogsService = props.structuredLogsService;
    const snackbarCtx = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const [currentSearchForm, setCurrentSearchForm] = useState<RequestedSearchForm | undefined>();
    const [searchResults, setSearchResults] = useState<Array<RequestStructuredLog>>([]);
    const [cursor, setCursor] = useState<Cursor | undefined>();
    const [count, setCount] = useState({} as Count);

    const searchHandler = (requestedSearchForm: RequestedSearchForm) => {
        setCursor(undefined);
        setSearchResults([]);
        setCurrentSearchForm(requestedSearchForm)
    }

    useEffect(() => {
        if (currentSearchForm) {
            fetchData(currentSearchForm, false);
        }
    }, [currentSearchForm]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = (requestedSearchForm: RequestedSearchForm, concat: boolean) => {
        setLoading(true);

        var structuredLogConnectionPromise: Promise<RequestStructuredLogConnection>;

        if (isBlank(requestedSearchForm.source)
            && isBlank(requestedSearchForm.documentType)
            && isBlank(requestedSearchForm.requestType)
            && isBlank(requestedSearchForm.errorCode)
            && isBlank(requestedSearchForm.printer)
            && isBlank(requestedSearchForm.sourceId)
            && isBlank(requestedSearchForm.traceId)
            && !requestedSearchForm.startDate
            && !requestedSearchForm.endDate
        ) {
            structuredLogConnectionPromise = structuredLogsService.listRequests(
                cursor?.nextToken,
                requestedSearchForm.startDate,
                requestedSearchForm.endDate);
        } else {
            structuredLogConnectionPromise = structuredLogsService.queryOpenSearch(
                cursor?.sortCursor,
                requestedSearchForm.globalIdSearch,
                requestedSearchForm.source,
                requestedSearchForm.documentType,
                requestedSearchForm.requestType,
                requestedSearchForm.errorCode,
                sanitize(requestedSearchForm.printer),
                sanitize(requestedSearchForm.sourceId),
                sanitize(requestedSearchForm.traceId),
                requestedSearchForm.startDate,
                requestedSearchForm.endDate);
        }

        structuredLogConnectionPromise
            .then(result => {
                setLoading(false);
                setCount({value: result.count?.value, relation: result.count?.relation});
                if (result.items) {
                    const filteredItems: RequestStructuredLog[] = result.items.flatMap(item => item ? [item] : []);
                    setSearchResults(concat ? searchResults.concat(filteredItems) : filteredItems)
                }
                if (result.nextToken) {
                    setCursor({nextToken: result.nextToken, sortCursor: undefined});
                } else if (result.sortCursor) {
                    setCursor({nextToken: undefined, sortCursor: result.sortCursor});
                } else {
                    setCursor(undefined);
                }
            })
            .catch(error => {
                setLoading(false);
                snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
            });
    }

    return (
        <div className="eds-flex eds-flex--direction-column eds-gap--16">
            <PrintRequestSearchForm onSearch={searchHandler} count={count}/>
            <PrintRequestSearchResults searchResults={searchResults} loading={loading}/>
            <div>
                {cursor ?
                    <Button
                        onClick={() => {
                            fetchData(currentSearchForm as RequestedSearchForm, true);
                        }}
                        variant="primary"
                        size={"small"}
                    >Load more
                    </Button>
                    : ""}
            </div>
        </div>
    )
}

