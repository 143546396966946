export interface Option {
    value: string;
    label: string;
    rank?: number;
}

export function sort<T extends Option>(optionsToSort: T[]): T[] {
    return optionsToSort.sort((a, b) => {
        if (a.rank !== undefined && b.rank !== undefined) {
            return a.rank - b.rank;
        } else if (a.rank !== undefined) {
            return -1;
        } else if (b.rank !== undefined) {
            return 1;
        } else {
            return a.label.localeCompare(b.label);
        }
    });
}