import {Card, Tab, TabGroup} from "@nike/eds";
import React from "react";
import {PrintRequestDetailOverviewDocumentEvents} from "./PrintRequestDetailOverviewDocumentEvents";
import {PrintRequestDetailOverviewLogging} from "./PrintRequestDetailOverviewLogging";
import {StringParam, useQueryParam, withDefault} from "use-query-params";

function PrintRequestDetailOverview(props) {

    const [activeId, setActiveId] = useQueryParam('tab', withDefault(StringParam, "documentEventsTab"));

    return (
        <Card className="eds-spacing--p-8">
            {/* FilterableTable of events/logging has margins of it own, so make margins of the Card smaller and add margins for the tabgroup to avoid large margins for the tables */}
            <div className="eds-spacing--p-24">
                <TabGroup
                    className=""
                    name="tab-button-group"
                    activeId={activeId}
                    onChange={e => setActiveId(e.target.id)}
                >
                    <Tab id="documentEventsTab">Document Events</Tab>
                    <Tab id="loggingTab">Logging</Tab>
                </TabGroup>
            </div>
            <PrintRequestDetailOverviewDocumentEvents activeId={activeId}
                                                      structuredLogsService={props.structuredLogsService}
                                                      events={props.events}/>
            <PrintRequestDetailOverviewLogging activeId={activeId}
                                               structuredLogsService={props.structuredLogsService}
                                               request={props.request}
                                               events={props.events}/>
        </Card>
    )
        ;
}

export {PrintRequestDetailOverview};