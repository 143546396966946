import React, {useState, useEffect} from "react";
import {useMemo} from "react";
import {LogMessage, RequestStructuredLog} from "../../graphql/api";
import {FilterableTable, formatDate} from "node.glds-react-component-library";
import {ButtonGroup, IconButton, Modal, Text} from "@nike/eds";
import {CopyToClipboard} from "react-copy-to-clipboard";

const REGEX_BACKSLASH_N = /\\n/gi;

function PrintRequestDetailOverviewLogging(props) {

    const [loading, setLoading] = useState(true);
    const structuredLogsService = props.structuredLogsService;
    const emptyArrayOfEvents: LogMessage[] = [];
    const [data, setData] = useState(emptyArrayOfEvents);
    const request: RequestStructuredLog = props.request;
    const [showStackTraceModal, setShowStackTraceModal] = useState(false);
    const [stackTraceModal, setStackTraceModal] = useState("");

    const handleViewClicked = async (e, props) => {
        if (props.row.original.stackTrace) {
            let stackTrace = props.row.original.stackTrace.replace(REGEX_BACKSLASH_N, "\n");
            setStackTraceModal(stackTrace);
            setShowStackTraceModal(true);
        }
    };

    const columns = useMemo(() => [
        {
            Header: "Timestamp",
            accessor: "timestamp",
            Cell: (props) => {
                return (
                    <>
                        {formatDate(props.value)}
                    </>
                );
            }
        },
        {
            Header: "Service",
            accessor: "componentName"
        },
        {
            Header: "Level",
            accessor: "logLevel"
        },
        {
            Header: "Message",
            accessor: "message"
        },
        {
            Header: "",
            accessor: "stackTrace",
            Cell: (props) => {
                return (
                    !!props.value ?
                        <IconButton
                            id={props.row.id}
                            size={"small"}
                            icon="Alert"
                            label={"View"}
                            onClick={(e) => handleViewClicked(e, props)}
                        />
                        :
                        <>
                        </>
                );
            }
        }
    ], []);

    useEffect(() => {
        if (request) {
            structuredLogsService.getLogMessages(request.traceId).then(result => {
                setLoading(false);
                setData(result.data.getLogMessages.items);
            });
        }
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div hidden={props.activeId !== 'loggingTab'}>
            <FilterableTable data={data} columns={columns} loading={loading}/>
            <Modal
                onDismiss={() => setShowStackTraceModal(!showStackTraceModal)}
                isOpen={showStackTraceModal}
                headerSlot={"Stacktrace"}
                footerSlot={
                    <ButtonGroup>
                        <CopyToClipboard text={stackTraceModal}>
                            <IconButton
                                size={"small"}
                                icon="CopyPaste"
                                label={"CopyPaste"}
                            />
                        </CopyToClipboard>
                    </ButtonGroup>
                }
            >
                <div>
                    <Text font={"body-3"} as={"div"}>
                        <pre id="formattedContent">{stackTraceModal}</pre>
                    </Text>
                </div>
            </Modal>
        </div>
    );
}

export {PrintRequestDetailOverviewLogging};