import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as ReactDOMClient from 'react-dom/client';
import "@nike/eds/dist/index.css";
import {BrowserRouter} from "react-router-dom";
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import {DownloadContextProvider, SnackBarContextProvider} from "node.glds-react-component-library";

let requestOverviewContainer = null;
let root = null;

window.renderRequestOverview = (containerId, history) => {
    if (!requestOverviewContainer) {
        requestOverviewContainer = document.getElementById(containerId);
        root = ReactDOMClient.createRoot(requestOverviewContainer);
        root.render(
                <BrowserRouter>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                        <SnackBarContextProvider>
                            <DownloadContextProvider>
                                <App history={history}/>
                            </DownloadContextProvider>
                        </SnackBarContextProvider>
                    </QueryParamProvider>
                </BrowserRouter>
        );
        serviceWorker.unregister();
    }
};

window.unmountRequestOverview = containerId => {
    root.unmount()
    requestOverviewContainer = null;
    root = null;
};

if (!document.getElementById('RequestOverview-container')) {
    //root.render(<App />);
    serviceWorker.unregister();
}


