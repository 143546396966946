/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getLogMessages = /* GraphQL */ `query GetLogMessages($traceId: String!, $count: String, $earliestTime: String) {
  getLogMessages(
    traceId: $traceId
    count: $count
    earliestTime: $earliestTime
  ) {
    items {
      timestamp
      logLevel
      message
      componentName
      stackTrace
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLogMessagesQueryVariables,
  APITypes.GetLogMessagesQuery
>;
export const getStructuredLogs = /* GraphQL */ `query GetStructuredLogs($requestId: String!) {
  getStructuredLogs(requestId: $requestId) {
    items {
      requestId
      hashKey
      sortKey
      traceId
      tenant
      errorCode
      errorDescription
      documentType
      requestType
      sourceId
      printer
      status
      duration
      type
      epochTimestamp
      requestTimestamp
      responseTimestamp
      message
      timestamp
      componentName
      region
      errorMessage
      payloadRegion
      payloadBucket
      payloadPath
      payloadVersion
      properties
      requestSummary {
        sourceId
        documentType
        requestId
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStructuredLogsQueryVariables,
  APITypes.GetStructuredLogsQuery
>;
export const getPayload = /* GraphQL */ `query GetPayload(
  $region: String!
  $bucket: String!
  $key: String!
  $version: String!
) {
  getPayload(region: $region, bucket: $bucket, key: $key, version: $version) {
    content
    contentType
    contentEncoding
    preSignedUrl
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPayloadQueryVariables,
  APITypes.GetPayloadQuery
>;
export const listRequests = /* GraphQL */ `query ListRequests(
  $epochTimestampFrom: String
  $epochTimestampTo: String
  $limit: Int
  $nextToken: String
) {
  listRequests(
    epochTimestampFrom: $epochTimestampFrom
    epochTimestampTo: $epochTimestampTo
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      requestId
      hashKey
      sortKey
      traceId
      tenant
      errorCode
      errorDescription
      documentType
      requestType
      sourceId
      printer
      status
      duration
      type
      epochTimestamp
      requestTimestamp
      responseTimestamp
      message
      requestSummary {
        sourceId
        documentType
        requestId
        __typename
      }
      __typename
    }
    nextToken
    sortCursor
    count {
      value
      relation
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRequestsQueryVariables,
  APITypes.ListRequestsQuery
>;
export const queryOpenSearch = /* GraphQL */ `query QueryOpenSearch(
  $globalIdSearch: Boolean
  $tenant: String
  $documentType: String
  $requestType: String
  $errorCode: String
  $status: String
  $printer: String
  $sourceId: String
  $traceId: String
  $epochTimestampFrom: String
  $epochTimestampTo: String
  $size: Int
  $sortCursor: [String!]
) {
  queryOpenSearch(
    globalIdSearch: $globalIdSearch
    tenant: $tenant
    documentType: $documentType
    requestType: $requestType
    errorCode: $errorCode
    status: $status
    printer: $printer
    sourceId: $sourceId
    traceId: $traceId
    epochTimestampFrom: $epochTimestampFrom
    epochTimestampTo: $epochTimestampTo
    size: $size
    sortCursor: $sortCursor
  ) {
    items {
      requestId
      hashKey
      sortKey
      traceId
      tenant
      errorCode
      errorDescription
      documentType
      requestType
      sourceId
      printer
      status
      duration
      type
      epochTimestamp
      requestTimestamp
      responseTimestamp
      message
      requestSummary {
        sourceId
        documentType
        requestId
        __typename
      }
      __typename
    }
    nextToken
    sortCursor
    count {
      value
      relation
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryOpenSearchQueryVariables,
  APITypes.QueryOpenSearchQuery
>;
