import {Card, Divider, IconButton, Label, Modal, StatusIndicator, Text, Tooltip} from "@nike/eds";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {RequestStructuredLog} from "../../graphql/api";
import {mapDocumentType, mapRequestType, mapSource} from "node.glds-react-component-library";
import {PropertyValue} from "../shared/PropertyValue";
import {formatZonedDateTime} from "../shared/DateFormatter";

function PrintRequestDetailHeader(props) {

    const [summaryModalVisible, setSummaryModalVisible] = React.useState(false);
    const request: RequestStructuredLog = props.request;
    const navigate = useNavigate();

    return (
        <Card className="eds-grid eds-grid--m-cols-5">
            <div className="eds-grid--m-col-2">
                <Label font="title-6">
                    {mapDocumentType(request?.documentType ?? '')}
                </Label>
            </div>
            <div className="eds-grid--m-col-2">
                <Tooltip bodySlot="SourceId / Request ID">
                    <Label font="body-3">
                        {request?.sourceId} / {(request?.requestId ?? '')}
                    </Label>
                </Tooltip>
            </div>
            <div className="eds-grid--m-col-1 eds-grid--item-justify-end">
                <IconButton
                    onClick={() => navigate(-1)}
                    icon="CaretLeft"
                    style={{marginRight: "5px"}}
                    size={"small"}
                    label={""}/>
                <IconButton
                    color="var(--eds-color-white)"
                    onClick={() => window.location.reload()}
                    icon="Repeat"
                    style={{marginRight: "5px"}}
                    size={"small"}
                    label={""}/>
                <IconButton
                    color="var(--eds-color-white)"
                    as="a"
                    href={`https://app.signalfx.com/#/apm/traces/${request?.traceId ?? ''}`}
                    icon="Report"
                    style={{marginRight: "5px"}}
                    size={"small"}
                    label={""}
                />
                {request?.requestSummary && request.requestSummary.length > 0 &&
                    <>
                        <IconButton
                            onClick={() => setSummaryModalVisible(!summaryModalVisible)}
                            label=""
                            size={"small"}
                            icon="Link"
                            style={{outline: 'none'}}
                        />
                        <Modal
                            isOpen={summaryModalVisible}
                            onDismiss={() => setSummaryModalVisible(false)}
                            headerSlot={"Group siblings of " + request.sourceId}
                        >
                            <Divider/>
                            <div className="eds-grid eds-grid--m-cols-3 group-siblings-grid">
                                {request.requestSummary.map(item => {
                                    return (
                                        <>
                                            <IconButton
                                                variant="ghost"
                                                as={Link}
                                                to={'../request-overview/details/' + item?.requestId}
                                                label=""
                                                icon="Show"
                                                className="eds-grid--item-center"
                                                onClick={() => {
                                                    setSummaryModalVisible(!summaryModalVisible);

                                                }}
                                            />
                                            <Text className="eds-grid--item-center">{mapDocumentType(item?.documentType as string)}</Text>
                                            <Text className="eds-grid--item-center">{item?.sourceId}</Text>
                                        </>
                                    );
                                })}
                            </div>
                        </Modal>
                    </>
                }
            </div>
            <PropertyValue label="Source" value={mapSource(request?.tenant ?? '')}/>
            <PropertyValue label="Trace ID" value={request?.traceId ?? ''}/>
            <PropertyValue label="Document type" value={mapDocumentType(request?.documentType ?? '')}/>
            <PropertyValue label="Request type" value={mapRequestType(request?.requestType ?? '')}/>
            <PropertyValue label="Printer" value={request && request.printer ? request.printer : ""}/>

            <PropertyValue label="Requested time" value={request?.requestTimestamp ? formatZonedDateTime(request.requestTimestamp) : ''}/>
            <PropertyValue label="Responded time" value={request?.responseTimestamp ? formatZonedDateTime(request.responseTimestamp) : ''}/>
            <PropertyValue label="Total duration (ms)" value={request ? request.duration : 0}/>
            <PropertyValue label="Status"
                           value={(request && request.status === null && <StatusIndicator status="inactive" size="large" label="Unknown"/>)
                               || (request && request.status === "OK" && <StatusIndicator status="success" size="large" label="Ok"/>)
                               || (request && request.status === "ERROR" && <StatusIndicator status="danger" size="large" label="Error"/>)}
            />
            <PropertyValue label={request && request.errorDescription ? "Error" : ""}
                           value={request && request.errorDescription ? request.errorDescription : ""}/>
            <div></div>

        </Card>
    );
}

export {PrintRequestDetailHeader};