import React, {useEffect, useState} from "react";
import {Button, Card, IconButton, Select, TextField} from "@nike/eds";
import {RequestedSearchForm} from "../../model/RequestedSearchForm";
import {BooleanParam, StringParam, useQueryParam, withDefault} from 'use-query-params';
import {documentTypesOptions} from "node.glds-react-component-library";
import useCookie from "react-use-cookie";
import {isNotBlank} from "../util/StringUtil";
import {sort} from "../util/OptionUtil";

function PrintRequestSearchForm(props) {

    // Variables to keep the model state
    const [cookieSource, setCookieSource] = useCookie('source', '');
    const [source, setSource] = useQueryParam('source', withDefault(StringParam, undefined));
    const [advancedSearch, setAdvancedSearch] = useQueryParam('advanced', withDefault(BooleanParam, false));
    const [sourceId, setSourceId] = useQueryParam('sourceId', withDefault(StringParam, undefined));
    const [printer, setPrinter] = useQueryParam('printer', withDefault(StringParam, undefined));
    const [traceId, setTraceId] = useQueryParam('traceId', withDefault(StringParam, undefined));
    const [documentType, setDocumentType] = useQueryParam('documentType', withDefault(StringParam, undefined));
    const [requestType, setRequestType] = useQueryParam('requestType', withDefault(StringParam, undefined));
    const [errorCode, setErrorCode] = useQueryParam('errorCode', withDefault(StringParam, undefined));
    const [startDate, setStartDate] = useQueryParam('startDate', withDefault(StringParam, null));
    const [endDate, setEndDate] = useQueryParam('endDate', withDefault(StringParam, null));

    // Variables to keep the UI state
    // Model queryParam need to be undefined to be removed from the url query params
    const [endDateValidationErrorMessage, setEndDateValidationErrorMessage] = useState('');
    const [startDateValidationErrorMessage, setStartDateValidationErrorMessage] = useState('');
    const [documentTypeSelectValue, setDocumentTypeSelectValue] = useState<any | null>(null);
    const [requestTypeSelectValue, setRequestTypeSelectValue] = useState<any | null>(null);
    const [sourceSelectValue, setSourceSelectValue] = useState<any | null>(null);
    const [errorCodeSelectValue, setErrorCodeSelectValue] = useState<any | null>(null);

    useEffect(() => {
        setDocumentTypeSelectValue(documentTypesOptions.find(e => e.value === documentType));
        setRequestTypeSelectValue(requestTypeOptions.find(e => e.value === requestType));
        setSourceSelectValue(sourceOptions.find(e => e.value === (source ?? cookieSource ?? '')));
        setErrorCodeSelectValue(errorCodeOptions.find(e => e.value === errorCode));
        performSearch();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const clearSearchCriteria = () => {
        setSourceId(undefined);
        setDocumentType(undefined);
        setDocumentTypeSelectValue(null);
        setSource('');
        setCookieSource('');
        setSourceSelectValue(null);
        setRequestType(undefined);
        setRequestTypeSelectValue(null);
        setErrorCode(undefined);
        setErrorCodeSelectValue(null);
        setStartDate(undefined);
        setEndDate(undefined);
        setPrinter(undefined);
        setTraceId(undefined);
    }

    const toggleSimpleAdvancedSearch = () => {
        if (advancedSearch) {
            setDocumentType(undefined);
            setDocumentTypeSelectValue(null);
            setRequestType(undefined);
            setRequestTypeSelectValue(null)
            setErrorCode(undefined);
            setErrorCodeSelectValue(null);
            setStartDate(undefined);
            setEndDate(undefined);
        } else {
            setSourceId(undefined);
        }
        setAdvancedSearch(!advancedSearch)
    }
    const sourceIdChangeHandler = (event) => {
        setSourceId(event.target.value === '' ? undefined : event.target.value);
    }
    const documentTypeChangeHandler = (event) => {
        setDocumentTypeSelectValue(event);
        setDocumentType(event !== null ? event.value : undefined);
    }
    const requestTypeChangeHandler = (event) => {
        setRequestTypeSelectValue(event);
        setRequestType(event !== null ? event.value : undefined);
    }
    const sourceChangeHandler = (event) => {
        setSourceSelectValue(event);
        setSource(event !== null ? event.value : undefined)
        setCookieSource(event !== null ? event.value : '')
    }
    const errorCodeChangeHandler = (event) => {
        setErrorCodeSelectValue(event);
        setErrorCode(event !== null ? event.value : undefined)
    }
    const startDateChangeHandler = (event) => {
        setStartDate(event.target.value === '' ? undefined : event.target.value);
    }
    const endDateChangeHandler = (event) => {
        setEndDate(event.target.value === '' ? undefined : event.target.value);
    }
    const printerChangeHandler = (event) => {
        setPrinter(event.target.value === '' ? undefined : event.target.value);
    }
    const traceIdChangeHandler = (event) => {
        setTraceId(event.target.value === '' ? undefined : event.target.value);
    }

    function validateSearchCriteriaDates(): boolean {
        setStartDateValidationErrorMessage('');
        setEndDateValidationErrorMessage('');
        if (!!startDate && !!endDate && (startDate > endDate)) {
            setStartDateValidationErrorMessage("The start date should be before the end date");
            setEndDateValidationErrorMessage("The end date should be after the start date");
            return false;
        }
        return true;
    }

    const sourceOptions = sort([
        {value: "1060", label: "Blue Ribbon"},
        {value: "1065", label: "Cortez"},
        {value: "1012", label: "Foothill Ranch"},
        {value: "1093", label: "Impact"},
        {value: "1063", label: "Samples"},
        {value: "1098", label: "Tepana"},
        {value: "1264", label: "The Court"},
        {value: "1067", label: "Windrunner"},
        {value: "1064", label: "Wings"},
    ]);

    const requestTypeOptions = sort([
        {value: "GENERATE", label: "Generate"},
        {value: "PRINT", label: "Print"},
        {value: "GENERATE_AND_PRINT", label: "Generate and Print"},
        {value: "GENERATE_AND_SAVE", label: "Generate and Save"}
    ]);

    const errorCodeOptions = sort([
        {value: "NO", label: "No errors", rank: 1},
        {value: "ALL", label: "All errors", rank: 2},
        {value: "INTERNAL_PRINTING_SERVICE_ERROR", label: "Internal printing service error"},
        {value: "CUPS_RESULT_IS_UNSUCCESSFUL", label: "Cups result is unsuccessful"},
        {value: "ERROR_WITH_AUTHENTICATION_SERVICE", label: "Error with authentication service"},
        {value: "ERROR_CODE_OUTPUT_DOCUMENT_NOT_FOUND", label: "Error code output document not found"},
        {value: "ERROR_CODE_OUTPUT_DOCUMENT_IS_CORRUPT", label: "Error code output document is corrupt"},
        {value: "FILE_NAME_COULD_NOT_BE_GENERATED_MISSING_CARTON_NUMBER", label: "File name could not be generated missing carton number"},
        {value: "FILE_NAME_COULD_NOT_BE_GENERATED_MISSING_LABEL_TYPE", label: "File name could not be generated missing label type"},
        {value: "GPS_INTERNAL_PRINTING_SERVICE_ERROR", label: "GPS Internal service error"},
        {value: "GPS_NO_PRINTER_DETERMINED", label: "Printer determination without results"},
        {value: "GPS_REQUIRED_DATA_FOR_GENERATION_NOT_AVAILABLE", label: "Required data for generation not available"},
        {value: "HOLDER_UNIQUE_ID_MISSING_IN_XML", label: "Holder unique id missing in xml"},
        {value: "LABEL_GENERATION_FAILED", label: "Label generation failed"},
        {value: "LABEL_GENERATOR_COULD_NOT_BE_REACHED", label: "Label generator could not be reached"},
        {value: "LABEL_NOT_PART_OF_SEQUENCE", label: "Label not part of sequence"},
        {value: "IFS_COMMUNICATION_FAILED", label: "IFS communication failed"},
        {value: "INPUT_DOCUMENT_NOT_FOUND", label: "Input document not found"},
        {value: "INTERNAL_PRINTING_SERVICE_ERROR", label: "Internal printing service error"},
        {value: "INTEGRATOR_DETERMINATION_FAILED", label: "Integrator determination failed"},
        {value: "INVALID_PRINTER_PARAMETERS", label: "Invalid printer parameters"},
        {value: "NO_DOCUMENT_EXISTS_FOR_PRINTING", label: "No document exists for printing"},
        {value: "NO_PRINTER_COULD_BE_SELECTED_FOR_THE_PROVIDED_PRINT_STATION", label: "No printer could be selected for the provided print station"},
        {value: "PRINTER_IS_UNAVAILABLE", label: "Printer is unavailable"},
        {value: "SEQUENCE_INCOMPLETE", label: "Sequence incomplete"},
        {value: "SHIPMENT_NOT_FOUND", label: "Shipment not found"},
        {value: "TEMPLATE_SELECTION_FAILED", label: "Template selection failed"},
        {value: "TIMEOUT_TOWARDS_GENERATOR", label: "Timeout towards generator"},
        {value: "TIMEOUT_TO_AUTHENTICATION_SERVICE", label: "Timeout to authentication service"},
        {value: "UNRECOGNIZED_ACTION_CODE", label: "Unrecognized action code"},
        {value: "VALIDATION_OF_INPUT_DOCUMENT_FAILED", label: "Validation of input document failed"},
        {value: "WRITE_TO_S3_FAILED", label: "Write to s3 failed"},
    ]);

    const submitHandler = (event) => {
        event.preventDefault();
        performSearch();
    }

    function performSearch() {
        if (!validateSearchCriteriaDates()) {
            return;
        }

        let searchRequestData: RequestedSearchForm = {}
        searchRequestData.globalIdSearch = !advancedSearch;
        if (isNotBlank(source)) {
            setCookieSource(source ?? ''); // set the cookie with overruling parameter
            searchRequestData.source = source
        } else if (isNotBlank(cookieSource)) {
            setSource(cookieSource); // set parameter with cookie if that one only is available
            searchRequestData.source = cookieSource
        }
        if (isNotBlank(sourceId)) {
            searchRequestData.sourceId = sourceId
        }
        if (advancedSearch && isNotBlank(documentType)) {
            searchRequestData.documentType = documentType
        }
        if (advancedSearch && isNotBlank(requestType)) {
            searchRequestData.requestType = requestType
        }
        if (advancedSearch && isNotBlank(printer)) {
            searchRequestData.printer = printer
        }
        if (advancedSearch && isNotBlank(traceId)) {
            searchRequestData.traceId = traceId
        }
        if (advancedSearch && isNotBlank(errorCode)) {
            searchRequestData.errorCode = errorCode
        }
        if (advancedSearch && isNotBlank(startDate)) {
            searchRequestData.startDate = new Date(startDate ?? '')
        }
        if (advancedSearch && isNotBlank(endDate)) {
            searchRequestData.endDate = new Date(endDate ?? '')
        }
        props.onSearch(searchRequestData);
    }

    return (
        <form onSubmit={submitHandler}>
            <Card className="eds-grid eds-grid--m-cols-5 eds-gap--16">
                <div className="searchField eds-grid--col-start-1 eds-grid--row-start-1">
                    <Select onChange={sourceChangeHandler}
                            value={sourceSelectValue}
                            id="sourceSelect"
                            options={sourceOptions}
                            label="Source"
                            placeholder="Select source..."
                            isClearable={true}
                    />
                </div>
                <div className="searchField eds-grid--col-start-2 eds-grid--row-start-1">
                    <TextField onChange={sourceIdChangeHandler}
                               id="sourceIdSearchField"
                               type="text"
                               hasErrors={false}
                               label={advancedSearch ? "Source ID" : "Identifier"}
                               placeholder={advancedSearch ? "Carton number / Order Id / SKU" : "Source / Trace / Request Id"}
                               value={sourceId ?? ""}
                    />
                </div>
                <div className="searchField eds-grid--col-start-1 eds-grid--row-start-2" hidden={!advancedSearch}>
                    <Select onChange={errorCodeChangeHandler}
                            value={errorCodeSelectValue}
                            isClearable={true}
                            id="errorCodeSelect"
                            label="Error code"
                            options={errorCodeOptions}
                    />
                </div>


                <div className="searchField eds-grid--col-start-2 eds-grid--row-start-2" hidden={!advancedSearch}>
                    <TextField onChange={traceIdChangeHandler}
                               id="traceIdSearchField"
                               type="text"
                               label="Trace ID"
                               placeholder="Trace ID"
                               value={traceId ?? ""}
                    />

                </div>
                <div className="searchField eds-grid--col-start-3 eds-grid--row-start-1" hidden={!advancedSearch}>
                    <Select onChange={documentTypeChangeHandler}
                            value={documentTypeSelectValue}
                            isClearable={true}
                            id="documentTypeSelect"
                            options={documentTypesOptions}
                            label="Document type"
                    />

                </div>
                <div className="searchField eds-grid--col-start-3 eds-grid--row-start-2" hidden={!advancedSearch}>
                    <Select onChange={requestTypeChangeHandler}
                            value={requestTypeSelectValue}
                            isClearable={true}
                            id="requestTypeSelect"
                            options={requestTypeOptions}
                            label="Request type"
                    />

                </div>
                <div className="searchField eds-grid--col-start-4 eds-grid--row-start-1" hidden={!advancedSearch}>
                    <TextField
                        onChange={startDateChangeHandler}
                        value={startDate ?? ""}
                        id="id"
                        type="datetime-local"
                        hasErrors={!!startDateValidationErrorMessage}
                        errorMessage={startDateValidationErrorMessage}
                        label="Enter a start date"
                    />
                </div>
                <div className="searchField eds-grid--col-start-4 eds-grid--row-start-2" hidden={!advancedSearch}>
                    <TextField
                        onChange={endDateChangeHandler}
                        value={endDate ?? ""}
                        id="id"
                        type="datetime-local"
                        hasErrors={!!endDateValidationErrorMessage}
                        errorMessage={endDateValidationErrorMessage}
                        label="Enter an end date"
                    />
                </div>
                <div className="eds-grid--col-start-5 eds-grid--col-end-6 eds-grid--row-start-1 eds-grid--item-justify-end relative">
                    <div className="eds-gap--8 eds-flex eds-flex--direction-row">
                        <div>
                            <IconButton
                                variant="primary"
                                type={"submit"}
                                size={"small"}
                                icon="Search"
                                label={""}/>
                        </div>
                        <div>
                            <IconButton
                                onClick={clearSearchCriteria}
                                variant="primary"
                                icon="Close"
                                size={"small"}
                                label={""}/>
                        </div>
                        <div>
                            <Button
                                onClick={toggleSimpleAdvancedSearch}
                                variant="primary"
                                size={"small"}
                            >{!advancedSearch ? 'Advanced Search' : 'Simple Search'}
                            </Button>
                        </div>
                    </div>
                    {props.count?.value ?
                        <div className="count">
                            Total hits: {(props.count.relation === "Gte" ? "+" : "") + props.count.value}
                        </div>
                        : ""
                    }
                </div>
                <div className="searchField eds-grid--col-start-5 eds-grid--row-start-2" hidden={!advancedSearch}>
                    <TextField onChange={printerChangeHandler}
                               id="printerSearchField"
                               type="text"
                               label="Printer"
                               placeholder="Full printer name"
                               value={printer ?? ""}
                    />
                </div>
            </Card>
        </form>
    );
}

export {
    PrintRequestSearchForm
}
